<div class="app_content-card">
  <div class="app_content-titles">Privacy and Cookie Policy</div>
</div>

<div class="app_content-card">
  <div class="app_content-sub-title">Identification / Contact</div>
  <div>
    <div>This website is solely run by Jon Lervold.</div>
    <div>It is a non-commercial project intended for personal use.</div>
  </div>
  <div>Contact - <i>jonlervold@gmail.com</i></div>

  <div class="app_content-sub-title">Cookies</div>
  <div>
    <div>This website requires one cookie to log in.</div>
    <div>
      By logging in, the user provides consent for an authorization token to be
      placed in the browser's local storage.
    </div>
    <div>
      This token is used only for determining if requests made to update/delete
      data are properly authorized.
    </div>
    <div>
      This cookie is not used for any sort of tracking of user activity on this
      website or elsewhere.
    </div>
    <div>
      The cookie is removed entirely upon using the user interface's log out
      functionality.
    </div>
  </div>

  <div class="app_content-sub-title">Data Storage</div>
  <div>
    <div>
      This platform uses email addresses for logins, and therefore login email
      addresses are saved in the database.
    </div>
    <div>
      This website stores all user-entered project and category information in
      the database.
    </div>
    <div>This is necessary for the core functionality of the application.</div>
    <div>
      Users have full rights to access, modify, and erase their own data via the
      user interface.
    </div>
    <div>
      Data entered into this platform will be stored until the user removes it.
    </div>
    <div>
      Data is permanently deleted from the database when the user deletes the
      data within the user interface.
    </div>
    <div>
      To fully delete all traces of an account, email the address in the contact
      section.
    </div>
  </div>

  <div class="app_content-sub-title">Data Privacy</div>
  <div>
    <div>
      Data that is entered into this platform will NEVER be sold to third
      parties.
    </div>
    <div>Data is protected by a JSON web token authentication process.</div>
    <div>JSON web token data is encrypted.</div>
    <div>Passwords are hashed and salted in the database.</div>
  </div>

  <div class="app_content-sub-title">Liability</div>
  <div>
    <div>
      Use of this platform is entirely at the user's own risk and discretion.
    </div>
    <div>
      The entering of sensitive personal data is explicitly prohibited by the
      terms of use.
    </div>
    <div>
      The operator of this website are not responsible for any damages or losses
      incurred by using this platform.
    </div>
    <div>
      By using this platform the user agrees not to hold the operator of this
      website responsible for any such claims.
    </div>
    <div>
      The operator makes no warranties or guarantees as to the accuracy,
      reliability, or availability of this website or any of its contents.
    </div>
    <div>
      It is the user's responsibility to use this platform following all of
      their locality's laws.
    </div>
    <div>
      Information in the database is subject to search, modification, and
      removal for moderation purposes.
    </div>
    <div>
      Any discovered illegal content will be immediately removed and, if
      necessary, law enforcement will be contacted.
    </div>
  </div>
</div>
