<div class="app_content-card">
  <div class="app_content-titles">Reset Password</div>
</div>

<div class="app_content-card">
  <div class="app_text-cursor-group">
    <table>
      <tr>
        <td>New Password</td>
        <td><input type="password" v-model="passwordField" /></td>
      </tr>

      <tr>
        <td>Verify New Password</td>
        <td><input type="password" v-model="passwordVerifyField" /></td>
      </tr>
    </table>
  </div>

  <div>
    <button @click="onSubmitPasswordResetClick">Submit</button>
  </div>

  <div>{{submissionProblems.join(' ')}}</div>
</div>
