<div class="app_content-card">
  <div class="app_content-titles">Projects</div>
</div>

<div class="projects-manager_filter-comps">
  <FilterManager />

  <ActiveFilters />
</div>

<ProjectsTable />
