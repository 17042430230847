<div class="app_content-card">
  <div class="app_content-titles">Forgot Password</div>
</div>

<div class="app_content-card app_text-cursor-group">
  <div>
    Email
    <input
      type="text"
      v-model="emailField"
      @keyup.enter="onSubmitPasswordReset"
    />
  </div>

  <div>
    <button @click="onSubmitPasswordReset">Submit</button>
  </div>

  <div v-if="submissionProblems.length > 0">
    {{submissionProblems.join(' ')}}
  </div>
</div>
