<div class="app_content-card">
  <div class="app_content-titles">Change Password</div>
</div>

<div class="app_content-card">
  <div class="app_content-sub-title">{{email}}</div>

  <div class="app_text-cursor-group">
    <table>
      <tr>
        <td>Current Password</td>
        <td>
          <input
            type="password"
            v-model="currentPasswordField"
            @keyup.enter="onSubmitChangePassword"
          />
        </td>
      </tr>

      <tr>
        <td>New Password</td>
        <td>
          <input
            type="password"
            v-model="newPasswordField"
            @keyup.enter="onSubmitChangePassword"
          />
        </td>
      </tr>

      <tr>
        <td>Verify New Password</td>
        <td>
          <input
            type="password"
            v-model="newPasswordVerifyField"
            @keyup.enter="onSubmitChangePassword"
          />
        </td>
      </tr>
    </table>
  </div>

  <div>
    <button @click="onSubmitChangePassword">Submit</button>
  </div>

  <div v-if="submissionProblems.length > 0">
    {{submissionProblems.join(' ')}}
  </div>
</div>
